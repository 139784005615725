<template>
  <div class="hello">
    <!--横屏提示-->
    <!-- <div id="orientLayer" class="mod-orient-layer">
      <div class="mod-orient-layer__content">
        <i class="icon mod-orient-layer__icon-orient"></i>
        <div class="mod-orient-layer__desc">为了更好的体验，请使用横屏浏览</div>
      </div>

    </div> -->
    <div id="orientLayer">
      <!-- <OrientLayer></OrientLayer> -->
      <Scroll />
    </div>
    <!-- <TextScroll :lists="lists"></TextScroll> -->
    <!--预加载动画-->
    <div class="page-loader" v-if="showLoader">
      <div class="page-loader-inner">
        <div class="spinner">
          <div class="double-bounce1" />
          <div class="double-bounce2" />
        </div>
      </div>
    </div>
    <!--培训动画-->
    <!-- <div class="page-loader">
      <div class="page-loader-inner">
        <Lottie :options="studyOptions" class="animation" :width="500" />
      </div>
    </div> -->
    <!-- <a href="javascript:;" class="belowbtn  bbtn3" style="right: 60vh;" @click="testDownloadBlob">
      <div class="b-feedback">
        <div class="b-text belowbtns belowbtns2">测试下载</div>
      </div>
    </a> -->

    <!--公告 -->
    <a data-fancybox data-src="#announcement" data-modal="false" href="javascript:;" class="belowbtn  bbtn2"
      style="right: 37vh;">
      <div class="b-feedback">
        <div class="b-text belowbtns belowbtns2" data-i18n="Opinion.head">{{ $t('BulletinBoard.head') }}</div>
      </div>
    </a>
    <!-- <a data-fancybox data-src="#drivingLicense" data-modal="true" href="javascript:;" class="feedbackbtn" style="right: 20%;">
      <div class="building b-feedback">
        <div class="b-text feedbackbtns" data-i18n="PersonalGlory.head">{{$t('PersonalGlory.head')}}</div>
      </div>
    </a> -->
    <!-- <a data-fancybox data-src="#gov" data-modal="true" href="javascript:;" class="feedbackbtn fbtn2" style="right: 8%;">
      <div class="building b-feedback">
        <div class="b-text feedbackbtns" data-i18n="Bonus.head">{{$t('Bonus.head')}}</div>
      </div>
    </a> -->
    <!--语言切换-->
    <SelectLanguage />
    <!-- <a href="javascript:;" id="changeLang" class="lang" data-lang="zh"></a> -->
    <!--意见反馈-->
    <!-- <a href="javascript:;" id="feedback" data-fancybox class="feedbackbtn" data-src="#Opinion" data-i18n="Opinion.head"></a> -->
    <!--意见反馈-->
    <a data-fancybox data-src="#Opinion" data-modal="false" href="javascript:;" class="belowbtn bbtn1"
      style="right: 17.5vh;">
      <div class="b-feedback">
        <div class="b-text belowbtns" data-i18n="Opinion.head">{{ $t('Opinion.head') }}</div>
      </div>
    </a>
    <!--更多问题Robot-->
    <div class="top-navigation">
      <a href="https://cnrobot.roche.com.cn/workweixin?domain=t-e&type=Pharma" class="feedbackbtn" target="_black">
        <div class="b-feedback">
          <div class="b-text feedbackbtns b-text-width" data-i18n="More.head">{{ $t('More.head') }}</div>
        </div>
      </a>
    </div>
    <!--搜索框-->
    <div class="top-navigation">
      <div class="searchInput">
        <form @submit.prevent="submitFn">
          <div class="search-wrapper">
            <div class="input-holder">
              <input type="text" class="search-input" :placeholder="$t('SearchBtn.placeholder')" @focus="onSearchFocus" v-model="inputMessage"
                @blur="onBlur" />
              <button class="search-icon" @click="searchToggle"><span />
                <div class="searchBtnP" data-i18n="SearchBtn.head">{{ $t('SearchBtn.head') }}</div>
              </button>
            </div>
            <span class="close" @click="searchToggle" />
            <div class="result-container" />
          </div>
        </form>
      </div>
    </div>
    <div id="SearchBody" class="b-modal searchInps">
      <a href=" " data-fancybox-close class="m-close">
        <span class="iconfont icon-guanbi" />
      </a>
      <div class="modal-inner">
        <h2 v-if="language === 'zh'" class="m-header">
          "&nbsp;<span class="keyword" style="color:red;">{{ queriesValue }}</span>&nbsp;" &nbsp;{{ $t('SearchBody.head')
          }}
        </h2>
        <h2 v-if="language === 'en'" class="m-header">
          &nbsp;{{ $t('SearchBody.head') }}&nbsp; &nbsp;"<span class="keyword" style="color:red;">{{ queriesValue
          }}</span>"
        </h2>
      </div>
      <div class="m-body">
        <a-spin tip="正在搜索...请稍后" :spinning="loading">
          <template>
            <ul v-if="isShowSearch" class="ulpadding">
              <li v-for="(item, index) of processedData" :key="index">
                <div class="middle">
              <li class="item">
                <a-collapse v-model="activeKey" :expand-icon-position="expandIconPosition">
                  <a-collapse-panel :key="item[0]" :header="$t(item[0] + '.head')" class="btn" forceRender>
                    <div v-if="language === 'zh' && item[1].id" class="smenu">
                      <a v-for="(it, i) in item" :key="i" @click="onClick(it, 'zh')">{{ it.cntitle }}</a>
                    </div>
                    <div v-if="language === 'en' && item[1].id" class="smenu">
                      <a v-for="(it, i) in item" :key="i" @click="onClick(it, 'en')">{{ it.entitle }}</a>
                    </div>
                    <template #extra><a @click="openCategory($event, item[0])"><img src="./../assets/img/open.png"
                          class="openImage"></a></template>
                  </a-collapse-panel>
                </a-collapse>
              </li>
      </div>
      </li>
      </ul>
      <ul v-else class="ulpadding">
        <a v-if="language === 'zh'">
          <a-result status="404" title="暂无数据" sub-title="抱歉，暂时没有找到相关数据">
            <template #extra />
          </a-result>
        </a>
        <a v-if="language === 'en'">
          <a-result status="404" title="No Data" sub-title="Sorry, No data have been found.">
            <template #extra />
          </a-result>
        </a>
      </ul>
      </template>
      </a-spin>
    </div>
  </div>
  <!--进度条-->
  <div class="timeline" v-if="trainArray.length > 0">
    <MyProgress id="myProgress" @openHumanTrack="backToRobot" :toal="trainArray.length" :current="myCurrentNode"
      :imageUrl="imageUrl" :trainList="trainArray" />
  </div>
  <div class="timelineImage" v-else>
    <img v-if="fixedImageUrl" :src="fixedImageUrl" alt="导航" class="myImg">
  </div>
  <!--弹出框-->
  <SuccessCompleteDiag :trainName="trainName" @completeTrain="completeTrain" />
  <!--培训欢迎-->
  <TrainDialog v-if="trainArray.length > 0" :trainName="trainName" :toal="trainArray.length" :current="myCurrentNode"
    :trainList="trainArray" />
  <!--目录-->
  <a data-fancybox data-src="#Catalog" data-modal="false" href=" " class="meunbtn">
    <div class="b-text">{{ $t('Catalog.head') }}</div>
  </a>
  <!--我的合规信息-->
  <div id="components-dropdown-demo-placement">
    <div v-for="(placement, index) in placements" :key="placement">
      <a-dropdown :placement="placement">
        <a-button>
          <a href="javascript:;" class="feedbtn">
            <div class="b-feedback" data-i18n="Information.head">
              <div id="dropdownButton" class="b-text feedbackbtns feedbackbtns-myhg" @mouseenter="changeActive($event)"
                @mouseleave="removeActive($event)">{{ $t('Information.head') }}<a-icon class="i-aicon" type="down" />
              </div>
            </div>
          </a>
        </a-button>
        <template #overlay>
          <a-menu @mouseenter="changeActive2($event)" @mouseleave="removeActive2($event)">
            <a-menu-item>
              <a data-fancybox data-src="#drivingLicense" data-modal="true" href="javascript:;" class="feedbtn"
                @click="opneDivingLicense">
                <div class="b-feedback">
                  <div class="b-text minfeedbackbtns febtn2" data-i18n="PersonalGlory.head">{{ $t('PersonalGlory.head') }}
                  </div>
                </div>
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="https://apps.roche.com.cn/Credit/#/index" class="feedbtn" target="_black">
                <div class="b-feedback fabtn">
                  <div class="b-text minfeedbackbtns febtn" data-i18n="Bonus.head">{{ $t('Bonus.head') }}</div>
                </div>
              </a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <br v-if="index === 1" />
    </div>
  </div>
  <!--我的培训计划-->
  <!-- <a data-fancybox data-src="#PlanList" data-modal="false" href=" " class="myTrainList">
    <div class="b-text">{{ $t('PlanList.head') }}</div>
  </a> -->
  <!--左右拖动预览提示箭头-->
  <div id="left_arrow" style="display: flex; position: absolute; z-index: 100; bottom: calc(50% - 62px); left: 10px;">
    <img src="./../assets/img/left.png" style="width: 80px;opacity: 0.5" />
  </div>
  <div id="right_arrow" style="display: flex; position: absolute; z-index: 100; bottom: calc(50% - 62px); right: 10px;">
    <img src="./../assets/img/right.png" style="width:  80px;opacity: 0.5" />
  </div>
  <!--地图外部区域-->
  <div id="map_wrap" class="map-wrap">
    <!--地图内部区域-->
    <div class="map-wrap-inner">
      <div class="map-con">
        <div class="myPosition">
          <!--总经理-->
          <div class="b-CEO" />
          <!--1-活动与会议【原医院】-->
          <!--<a data-fancybox data-src="#hospital" data-modal="true" href="javascript:;" class="build-link">
            <div class="building b-hospital">
              <div class="b-text"></div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-hospital.png">
              </div>
            </div>
            <div class="b-ambulance"></div>
          </a> -->
          <a data-fancybox data-src="#Hospital" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Hospital')">
            <div class="building b-hospital">
              <div class="main b-text">
                <a-badge :count="allData.Hospital ? allData.Hospital.count : 0">
                  <CatalogTitle :category="allData.Hospital" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-hospital.png" />
              </div>
            </div>
            <div class="b-ambulance" />
          </a>
          <div id="Hospital" class="b-modal">
            <FancyBox :lists="allData.Hospital" :trainList="getMyTrainList('Hospital')"
              @changeTrainList="changeTrainList($event, 'Hospital')" />
          </div>
          <!--2-讲者-->
          <!--  <a data-fancybox data-src="#speaker" data-modal="true" href="javascript:;" class="build-link">
            <div class="building b-speaker">
              <div class="b-text"></div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-speaker.png">
              </div>
              <svg viewBox="0, 0, 450, 346" class="b-photo">
                <foreignObject class="fg" width="450" height="346">
                  <div class="svg-img"></div>
                </foreignObject>
              </svg>
            </div>
          </a> -->
          <a data-fancybox data-src="#Speaker" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Speaker')">
            <div class="building b-speaker">
              <div class="main b-text">
                <a-badge :count="allData.Speaker ? allData.Speaker.count : 0">
                  <CatalogTitle :category="allData.Speaker" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-speaker.png" />
              </div>
              <svg viewBox="0, 0, 450, 346" class="b-photo">
                <foreignObject class="fg" width="450" height="346">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Speaker" class="b-modal">
            <FancyBox :lists="allData.Speaker" :trainList="getMyTrainList('Speaker')"
              @changeTrainList="changeTrainList($event, 'Speaker')" />
          </div>
          <!--3-日常交往-->
          <a data-fancybox data-src="#HCP" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#HCP')">
            <div class="building b-HCP">
              <div class="b-text main">
                <a-badge :count="allData.HCP ? allData.HCP.count : 0">
                  <CatalogTitle :category="allData.HCP" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-hcp.png" />
              </div>
              <svg viewBox="0, 0, 479, 364" class="b-photo">
                <foreignObject class="fg" width="479" height="364">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="HCP" class="b-modal">
            <FancyBox :lists="allData.HCP" :trainList="getMyTrainList('HCP')"
              @changeTrainList="changeTrainList($event, 'HCP')" />
          </div>
          <!--4-赞助和捐助【原协会】-->
          <a data-fancybox data-src="#GSD" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#GSD')">
            <div class="building b-GSD">
              <div class="b-text main">
                <a-badge :count="allData.GSD ? allData.GSD.count : 0">
                  <CatalogTitle :category="allData.GSD" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-gsd.png" />
              </div>
            </div>
          </a>
          <div id="GSD" class="b-modal">
            <FancyBox :lists="allData.GSD" :trainList="getMyTrainList('GSD')"
              @changeTrainList="changeTrainList($event, 'GSD')" />
          </div>
          <!--5-采购-供应商系统及流程【原会议酒店】-->
          <a data-fancybox data-src="#Meetings" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Meetings')">
            <div class="building b-meetings">
              <div class="b-text main">
                <a-badge :count="allData.Meetings ? allData.Meetings.count : 0">
                  <CatalogTitle :category="allData.Meetings" />
                </a-badge>
              </div>
              <div class="b-photo">

                <img class="b-img" src="./../assets/img/b-meetings.png" />

              </div>
            </div>
          </a>
          <div id="Meetings" class="b-modal">
            <FancyBox :lists="allData.Meetings" :trainList="getMyTrainList('Meetings')"
              @changeTrainList="changeTrainList($event, 'Meetings')" />
          </div>
          <!--7-海陆空交通出行【原火车站 or 交通出行】-->
          <a data-fancybox data-src="#Train" data-modal="false" href="javascript:;" class="build-link"  @click="openFancybox($event,'#Train')">
            <div class="building b-train">
              <div class="b-text main">
                <a-badge :count="allData.Train ? allData.Train.count : 0">
                  <CatalogTitle :category="allData.Train" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-train.png" />
              </div>
              <svg viewBox="0, 0, 488, 256" class="b-photo">
                <foreignObject class="fg" width="488" height="256">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Train" class="b-modal">
            <FancyBox :lists="allData.Train" :trainList="getMyTrainList('Train')"
              @changeTrainList="changeTrainList($event, 'Train')" />
          </div>
          <!--8-餐厅-->
          <a data-fancybox data-src="#Restaurants" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Restaurants')">
            <div class="building b-restaurant">
              <div class="b-text main">
                <a-badge :count="allData.Restaurants ? allData.Restaurants.count : 0">
                  <CatalogTitle :category="allData.Restaurants" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-restaurant.png" />
              </div>
            </div>
          </a>
          <div id="Restaurants" class="b-modal">
            <FancyBox :lists="allData.Restaurants" :trainList="getMyTrainList('Restaurants')"
              @changeTrainList="changeTrainList($event, 'Restaurants')" />
          </div>
          <!--9-经销商和药店-->
          <a data-fancybox data-src="#DP" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#DP')">
            <div class="building b-drugstore">
              <div class="b-text main">
                <a-badge :count="allData.DP ? allData.DP.count : 0">
                  <CatalogTitle :category="allData.DP" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-drugstore.png" />
              </div>
            </div>
          </a>
          <div id="DP" class="b-modal">
            <FancyBox :lists="allData.DP" :trainList="getMyTrainList('DP')"
              @changeTrainList="changeTrainList($event, 'DP')" />
          </div>
          <!--10-罗卜信用【原政府官员】-->
          <a data-fancybox data-src="#GOV" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#GOV')">
            <div class="building b-gov">
              <div class="b-text main">
                <a-badge :count="allData.GOV ? allData.GOV.count : 0">
                  <CatalogTitle :category="allData.GOV" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-gov.png" />
              </div>
              <svg viewBox="0, 0, 358, 270" class="b-photo">
                <foreignObject class="fg" width="358" height="270">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="GOV" class="b-modal">
            <FancyBox :lists="allData.GOV" :trainList="getMyTrainList('GOV')"
              @changeTrainList="changeTrainList($event, 'GOV')" />
          </div>
          <!--11-材料审批-->
          <!-- <a data-fancybox id="approval" data-src="#approval" data-modal="true" href="javascript:;" class="build-link">
            <div class="building b-approval">
              <div class="b-text main">
                <CatalogTitle :category="allData.approval"></CatalogTitle>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-approval.png">
              </div>
            </div>
          </a>
          <div id="approval" class="b-modal">
            <FancyBox :lists="allData.approval"></FancyBox>
          </div> -->
          <a data-fancybox data-src="#Approval" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Approval')">
            <div class="building b-approval">
              <div class="b-text main">
                <a-badge :count="allData.Approval ? allData.Approval.count : 0">
                  <CatalogTitle :category="allData.Approval" />
                </a-badge>
              </div>
              <div class="b-photo">

                <img class="b-img" src="./../assets/img/b-approval.png" />

              </div>
            </div>
          </a>
          <div id="Approval" class="b-modal">
            <FancyBox :lists="allData.Approval" :trainList="getMyTrainList('Approval')"
              @changeTrainList="changeTrainList($event, 'Approval')" />
          </div>
          <!--12-合规拼图/培训【原培训流程】-->
          <a data-fancybox data-src="#Puzzle" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Puzzle')">
            <div class="building b-puzzle">
              <div class="b-text main">
                <a-badge :count="allData.Puzzle ? allData.Puzzle.count : 0">
                  <CatalogTitle :category="allData.Puzzle" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-puzzle.png" />
              </div>
              <svg viewBox="0, 0, 624, 439" class="b-photo">
                <foreignObject class="fg" width="624" height="439">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Puzzle" class="b-modal">
            <FancyBox :lists="allData.Puzzle" :trainList="getMyTrainList('Puzzle')"
              @changeTrainList="changeTrainList($event, 'Puzzle')" />
          </div>
          <!--13- 会议酒店（含高风险商户名单)-->
          <a data-fancybox data-src="#Nogo" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Nogo')">
            <div class="building b-travel">
              <div class="b-text main">
                <a-badge :count="allData.Nogo ? allData.Nogo.count : 0">
                  <CatalogTitle :category="allData.Nogo" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-travel.png" />
              </div>
            </div>
          </a>
          <div id="Nogo" class="b-modal">
            <FancyBox :lists="allData.Nogo" :trainList="getMyTrainList('Nogo')"
              @changeTrainList="changeTrainList($event, 'Nogo')" />
          </div>
          <!--14-常用软件及系统【原会务系统】-->
          <a data-fancybox data-src="#Club" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Club')">
            <div class="building b-club">
              <div class="b-text main">
                <a-badge :count="allData.Club ? allData.Club.count : 0">
                  <CatalogTitle :category="allData.Club" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-club.png" />
              </div>
              <svg viewBox="0, 0, 392, 383" class="b-photo">
                <foreignObject class="fg" width="392" height="383">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Club" class="b-modal">
            <FancyBox :lists="allData.Club" :trainList="getMyTrainList('Club')"
              @changeTrainList="changeTrainList($event, 'Club')" />
          </div>
          <!--15-政策流程动态【原会务用车】-->
          <a data-fancybox data-src="#Car" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Car')">
            <div class="building b-car">
              <div class="b-text main">
                <a-badge :count="allData.Car ? allData.Car.count : 0">
                  <CatalogTitle :category="allData.Car" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-car.png" />
              </div>
              <svg viewBox="0, 0, 406, 232" class="b-photo">
                <foreignObject class="fg" width="406" height="232">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Car" class="b-modal">
            <FancyBox :lists="allData.Car" :trainList="getMyTrainList('Car')"
              @changeTrainList="changeTrainList($event, 'Car')" />
          </div>
          <!--16-罗氏新手关爱村【原罗氏-其他信息】-->
          <a data-fancybox data-src="#Roche" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Roche')">
            <div class="building b-roche">
              <div class="b-text main">
                <a-badge :count="allData.Roche ? allData.Roche.count : 0">
                  <CatalogTitle :category="allData.Roche" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-roche.png" />
              </div>
            </div>
          </a>
          <div id="Roche" class="b-modal">
            <FancyBox :lists="allData.Roche" :trainList="getMyTrainList('Roche')"
              @changeTrainList="changeTrainList($event, 'Roche')" />
          </div>
          <!--17-SSC／Concur报销-->
          <a data-fancybox data-src="#SSC" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#SSC')">
            <div class="building b-concur">
              <div class="b-text main">
                <a-badge :count="allData.SSC ? allData.SSC.count : 0">
                  <CatalogTitle :category="allData.SSC" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-concur.png" />
              </div>
            </div>
          </a>
          <div id="SSC" class="b-modal">
            <FancyBox :lists="allData.SSC" :trainList="getMyTrainList('SSC')"
              @changeTrainList="changeTrainList($event, 'SSC')" />
          </div>
          <!--18-飞鹰护航-->
          <a data-fancybox data-src="#Spot" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Spot')">
            <div class="building b-spotCheck">
              <div class="b-text main">
                <a-badge :count="allData.Spot ? allData.Spot.count : 0">
                  <CatalogTitle :category="allData.Spot" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-spotcheck.png" />
              </div>
              <svg viewBox="0, 0, 380, 380" class="b-photo">
                <foreignObject class="fg" width="380" height="380">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Spot" class="b-modal">
            <FancyBox :lists="allData.Spot" :trainList="getMyTrainList('Spot')"
              @changeTrainList="changeTrainList($event, 'Spot')" />
          </div>
          <!--19-患者-->
          <a data-fancybox data-src="#OtherOther" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#OtherOther')">
            <div class="building b-other">
              <div class="b-text main">
                <a-badge :count="allData.OtherOther ? allData.OtherOther.count : 0">
                  <CatalogTitle :category="allData.OtherOther" />
                </a-badge>
              </div>
              <div class="b-photo">
                <img class="b-img" src="./../assets/img/b-other.png" />
              </div>
            </div>
          </a>
          <div id="OtherOther" class="b-modal">
            <FancyBox :lists="allData.OtherOther" :trainList="getMyTrainList('OtherOther')"
              @changeTrainList="changeTrainList($event, 'OtherOther')" />
          </div>
          <!--20-经理合规领导力【原合规第一线】-->
          <a data-fancybox data-src="#Hiring" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Hiring')">
            <div class="building b-hiring">
              <div class="b-text main">
                <a-badge :count="allData.Hiring ? allData.Hiring.count : 0">
                  <CatalogTitle :category="allData.Hiring" />
                </a-badge>
              </div>
              <div class="b-photo">

                <img class="b-img" src="./../assets/img/b-hiring.png" />

              </div>
            </div>
          </a>
          <div id="Hiring" class="b-modal">
            <FancyBox :lists="allData.Hiring" :trainList="getMyTrainList('Hiring')"
              @changeTrainList="changeTrainList($event, 'Hiring')" />
          </div>
          <!--21-法务合规【原飞机】-->
          <a data-fancybox data-src="#Flight" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Flight')">
            <div class="building b-aircraft">
              <div class="b-text main">
                <a-badge :count="allData.Flight ? allData.Flight.count : 0">
                  <CatalogTitle :category="allData.Flight" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-aircraft.png" />
              </div>
              <svg viewBox="0, 0, 404, 276" class="b-photo">
                <foreignObject class="fg" width="404" height="276">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Flight" class="b-modal">
            <FancyBox :lists="allData.Flight" :trainList="getMyTrainList('Flight')"
              @changeTrainList="changeTrainList($event, 'Flight')" />
          </div>
          <!--22-合规加油站【原大巴】-->
          <a data-fancybox data-src="#Bus" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Bus')">
            <div class="building b-bus">
              <div class="b-text main">
                <a-badge :count="allData.Bus ? allData.Bus.count : 0">
                  <CatalogTitle :category="allData.Bus" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-bus.png" />
              </div>
              <svg viewBox="0, 0, 260, 275" class="b-photo">
                <foreignObject class="fg" width="260" height="275">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Bus" class="b-modal">
            <FancyBox :lists="allData.Bus" :trainList="getMyTrainList('Bus')"
              @changeTrainList="changeTrainList($event, 'Bus')" @openDrivingLicense="opneDivingLicense"/>
          </div>
          <!--23-常用联系方式/常用问题汇总【原联系法务合规】-->
          <a data-fancybox data-src="#Contact" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#Contact')">
            <div class="building b-BPs">
              <div class="b-text main">
                <a-badge :count="allData.Contact ? allData.Contact.count : 0">
                  <CatalogTitle :category="allData.Contact" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-bps.png" />
              </div>
              <svg viewBox="0, 0, 589, 386" class="b-photo">
                <foreignObject class="fg" width="589" height="386">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="Contact" class="b-modal">
            <FancyBox :lists="allData.Contact" :trainList="getMyTrainList('Contact')"
              @changeTrainList="changeTrainList($event, 'Contact')" />
          </div>
          <!--24 合规先锋 敢为人先-->
          <a data-fancybox data-src="#PACE" data-modal="false" href="javascript:;" class="build-link" @click="openFancybox($event,'#PACE')">
            <div class="building b-champion">
              <div class="b-text main" id="PACE1">
                <a-badge :count="allData.PACE ? allData.PACE.count : 0">
                  <CatalogTitle :category="allData.PACE" />
                </a-badge>
              </div>
              <div class="b-photo" data-static>
                <img class="b-img" src="./../assets/img/b-champion.png" />
              </div>
              <svg viewBox="0, 0, 340, 435" class="b-photo">
                <foreignObject class="fg" width="340" height="435">
                  <div class="svg-img" />
                </foreignObject>
              </svg>
            </div>
          </a>
          <div id="PACE" class="b-modal">
            <FancyBox :lists="allData.PACE" :trainList="getMyTrainList('PACE')"
              @changeTrainList="changeTrainList($event, 'PACE')" />
          </div>
          <!--24 合规电影院-->
          <a data-fancybox data-src="#Cinema" data-modal="false" href="javascript:;" class="build-link"  @click="openFancybox($event,'#Cinema')">
            <div class="building b-cinema">
              <div class="b-text main">
                <a-badge :count="allData.Cinema ? allData.Cinema.count : 0">
                  <CatalogTitle :category="allData.Cinema" />
                </a-badge>
              </div>
              <div class="b-photo">

                <img class="b-img" src="./../assets/img/b-cinema.png" />

              </div>
            </div>
          </a>
          <div id="Cinema" class="b-modal">
            <FancyBox :lists="allData.Cinema" :trainList="getMyTrainList('Cinema')"
              @changeTrainList="changeTrainList($event, 'Cinema')" />
          </div>
          <!----自定义组件-->
          <Announcement @handleAnnouncementClose="openTrain" />
          <!-- <PersonalInformation></PersonalInformation> -->
          <!-----我的合规驾照-->
          <DrivingLicense v-if="showDivingLicense" />
          <Catalog />
          <MyVideo />
          <!-- <MyTrainList v-if="progressTrainList.length > 0" @backToRobot="backToRobot" :data="progressTrainList" /> -->
          <!-- 25 意见反馈 -->
          <!-- <a data-fancybox data-src="#Opinion" data-modal="true" href="javascript:;" class="build-link" >
                        <div class="building b-feedback">
                            <div class="b-text" ></div>
                        </div>
                    </a> -->
          <div id="Opinion" class="b-modal">
            <a href="javascript:;" data-fancybox-close class="m-close">
              <span class="fancybox-close-text" title="关闭">{{ $t('Fancbox.close') }}</span>
            </a>
            <div class="modal-inner">
              <h2 class="m-header" data-i18n="Opinion.head">
                {{ $t('Opinion.head') }}
              </h2>
              <div class="m-body">
                <div class="textareabox">
                  <textarea id="feedbackCont" maxlength="500" v-model="content" @input="getNumber" @blur="onTextareaBlur"
                    @focus="onTextareaFocus"
                    style="width:100%;height:100px;background:#ddb166;color:#915d14;padding:10px;border:1px solid #5d3a10;border-radius:10px;font-family: -apple-system,Helvetica,sans-serif;outline:none;" />
                  <span class="textareaSpan">{{ reciprocal }} / 500</span>
                </div>
                <p style="text-align: center;">
                  <button id="sendmail"
                    style="width:100px;height:30px;line-height:30px;background:#5d3a10;color:#fff;border-radius: 10px;border:1px solid #5d3a10;"
                    data-i18n="Opinion.submit"> {{ $t('Opinion.submit') }} </button>
                </p>
                “您的意见对我们非常重要，我们期待聆听到您的声音。收到您的意见后，我们将对合规地图进行适当调整，谢谢！”
              </div>
            </div>
          </div>
        </div>
        <!--底部地图-->
        <section class="mySection">
          <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 3239 750" enable-background="new 0 0 3239 750" xml:space="preserve">
            <g id="myCar" transform="translate(340 0)">
              <!---预定路径-->
              <g>
                <path visibility="hidden" id="curve2" fill="none" stroke="gray" stroke-width="0.5%" stroke-linecap="round"
                  stroke-dasharray="1% 2%" stroke-linejoin="round" stroke-miterlimit="10" d="
                                                                              M0,0c8.28,15.37,20.55,41.32,27.81,75.19c16.77,78.23-11.86,111.53-5.33,216c4.18,66.95,18.9,100.63,23.43,110.48
                                                                              c4.33,9.41,22.15,46.8,58.86,80.57c26.97,24.81,68.93,63.42,120,59.71c16.47-1.19,22.85-5.99,69.33-31.33
                                                                              c114.71-62.55,112.97-53.59,134.48-73.14c38.03-34.57,59.64-77.26,78.48-114.48c28.21-55.73,29.62-82.14,47.11-84.76
                                                                              c25.7-3.85,35.55,51.29,101.46,105.02c8.84,7.2,44.75,35.63,98.86,54.03c21.18,7.2,57.78,10.92,130.98,18.35
                                                                              c60.34,6.12,74.57,5.9,86.1-4.32c19.75-17.51,6.48-42.12,14.92-82.6c14.04-67.35,73.25-106.93,138.1-150.29
                                                                              c68.37-45.71,96.27-39.81,105.52-37.08c15.26,4.51,20.82,13.89,43.24,34.79c0,0,14.83,13.83,84.73,64.92
                                                                              c62.16,45.44,170.18,57.68,242.7,65.37c164.68,17.46,201.54-8.78,308.57,28c39.09,13.43,73.96,25.41,112.57,54.86
                                                                              c115.68,88.2,130.62,230.65,174.29,224.7c7.93-1.08,10.86-4.35,63.68-62.96c36.07-40.03,64.48-71.23,68.69-75.84
                                                                              c67.38-73.59,145.99-112.13,181.34-129.33c32.94-16.02,81.15-39.48,149.14-52.76c71.24-13.92,120.19-8.06,179.43-42.67
                                                                              c28.46-16.63,48.2-36.11,60.14-49.52" />
              </g>
              <!---实际路径-->
              <g>
                <path style="display:none" id="curve" fill="none" stroke="burlywood" stroke-width="1%"
                  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="
                                                                              M0,0c8.28,15.37,20.55,41.32,27.81,75.19c16.77,78.23-11.86,111.53-5.33,216c4.18,66.95,18.9,100.63,23.43,110.48
                                                                              c4.33,9.41,22.15,46.8,58.86,80.57c26.97,24.81,68.93,63.42,120,59.71c16.47-1.19,22.85-5.99,69.33-31.33
                                                                              c114.71-62.55,112.97-53.59,134.48-73.14c38.03-34.57,59.64-77.26,78.48-114.48c28.21-55.73,29.62-82.14,47.11-84.76
                                                                              c25.7-3.85,35.55,51.29,101.46,105.02c8.84,7.2,44.75,35.63,98.86,54.03c21.18,7.2,57.78,10.92,130.98,18.35
                                                                              c60.34,6.12,74.57,5.9,86.1-4.32c19.75-17.51,6.48-42.12,14.92-82.6c14.04-67.35,73.25-106.93,138.1-150.29
                                                                              c68.37-45.71,96.27-39.81,105.52-37.08c15.26,4.51,20.82,13.89,43.24,34.79c0,0,14.83,13.83,84.73,64.92
                                                                              c62.16,45.44,170.18,57.68,242.7,65.37c164.68,17.46,201.54-8.78,308.57,28c39.09,13.43,73.96,25.41,112.57,54.86
                                                                              c115.68,88.2,130.62,230.65,174.29,224.7c7.93-1.08,10.86-4.35,63.68-62.96c36.07-40.03,64.48-71.23,68.69-75.84
                                                                              c67.38-73.59,145.99-112.13,181.34-129.33c32.94-16.02,81.15-39.48,149.14-52.76c71.24-13.92,120.19-8.06,179.43-42.67
                                                                              c28.46-16.63,48.2-36.11,60.14-49.52" />
              </g>
              <g>
                <!--   <animateMotion id="myAnimation" :path="animationPath" :begin="animationStart" :dur="animationDur" fill="freeze"></animateMotion> -->
                <a-tooltip v-model="showTooltip" :mouseLeaveDelay="mouseLeaveDelay" trigger="click"
                  overlayClassName="myTooltip" id="myTooltip">
                  <template slot="title">
                    <p><span class="studyName">{{ getStudyName(currentIndex) }}</span><a-progress :percent="myPercent"
                        size="small" /></p>
                    <p class="animationTips">
                      <Lottie :options="defaultOptions" class="animation" :width="40" /><span
                        style="white-space: nowrap;margin-right: 2vh;">{{  $t("HumanImg.goTonext") }}</span>
                      <a style="font-weight: 700;" @click="openCategory($event, nextStep[step])">{{ $t("Sign.title1") }}{{ step + 1 }}{{
                        $t("Sign.title2") }} {{ $t(`${animationTips}.head`) }}</a>
                    </p>
                    <!-- <p><a href="javascript: void(0)" @click="goToNext" class="nextStage">{{ $t("HumanImg.goTonext") }}</a>
                    </p> -->
                  </template>
                  <Human @myHumanClick="clickHuman" :imageUrl="imageUrl" />
                </a-tooltip>
              </g>
            </g>
          </svg>
        </section>
      </div>
    </div>
    <!--地图内部区域 end -->
  </div>
  <!--地图外部区域 end -->
  <div id="toast" class="toast">
    <div class="double-bounce1" />
    <div class="double-bounce2" />
    <p>提交中...</p>
  </div>
  <div class="displayNone" id="topInput">
    <input class="topInput" v-model="inputMessage" @blur="onBlur" placeholder="请输入关键词" />
  </div>
  <div>
  </div>
  </div>
</template>

<script>
import '@/css/main.css'
import '@/css/media.css'
import $ from './../plugins/jquery.vendor.js'
import BScroll from './../plugins/bscroll.min.js'
import { enLocale, zhLocale } from '@/locale/myLocale'
import { getUrlParameter, isAndroid, deepClone, getPreifx, isMobile } from '@/utils/util.js'
import SelectLanguage from '@/components/SelectLanguage'
import Announcement from '@/components/Announcement'
import FancyBox from '@/components/FancyBox'
import Catalog from '@/components/Catalog'
import CatalogTitle from '@/components/CatalogTitle'
import DrivingLicense from '@/components/DrivingLicense'
import MyVideo from '@/components/MyVideo'
import MyProgress from '@/components/MyProgress'
import SuccessCompleteDiag from '@/components/SuccessCompleteDiag'
import TrainDialog from '@/components/TrainDialog'
import Human from '@/components/Human'
import { getUser, downloadFile, getIteminfos, getCn, getEn, sendFeedback, searchItems, getMobileoute, getDownloadBase64, downloadBlob, sendfinish, getImageinfo } from '@/api'
import Scroll from '@/components/Scroll'
import { mapGetters } from 'vuex'
import store from '@/store'
import Lottie from 'vue-lottie/src/lottie.vue'
import * as anData from '@/assets/lottie/arrow.json'
import { animationMapping } from '@/utils/constants'
import { saveAs } from "file-saver";
import mocktrainList from '@/data/course'

export default {
  name: 'Home',
  components: { SelectLanguage, FancyBox, Announcement, Catalog, CatalogTitle, DrivingLicense, Scroll, MyVideo, Human, MyProgress, SuccessCompleteDiag, Lottie, TrainDialog },
  data() {
    return {
      allData: {},
      originDataArray: [],
      placements: ['bottomLeft'],
      processedData: [],
      queriesValue: '',
      activeKey: [],
      isShowSearch: true,
      openKeyActive: '',
      content: '',
      reciprocal: 0,
      showLoader: true,
      inputMessage: '',
      expandIconPosition: 'left',
      animationStart: null,
      animationPath: '',
      showTooltip: true,
      animationTips: '',
      mapScroll: null,
      nextStep: ["OtherOther", "Hiring", "PACE", "Puzzle", "DP", "Car", "GOV", "HCP", "Hospital", "GSD", "Speaker", "Flight", "Roche", "Bus", "Train", "Contact", "Club", "Spot", "Restaurants", "Hospital", "Approval", "Nogo", "SSC", "Hiring", "Meetings"],
      nextS: ["3s", "3s", "3s", "3s", "3s"],
      step: 0,
      animationDur: '1s',
      mouseLeaveDelay: 30,
      pathLength: 0,
      human: null,
      curve: null,
      curve2: null,
      progress: 0,
      direction: 10,
      isMoving: false,
      requestId: null,
      maxLen: 0,
      originLen: 0,
      orgintrainList: [],
      progressTrainList: [],
      trainArray: [],
      myPercent: 0, // 进度
      myCurrentNode: 1, // myProgress 节点
      currentIndex: 0, // 当前第几个培训
      myProgress: null,
      trainName: "",
      imageUrl: "",
      defaultOptions: {
        animationData: anData.default
      },
      lastNumberSign: false, // 用来保存上一次数字的符号（正或负）
      animationMapping: animationMapping, // 获取动画配置
      showDivingLicense: false,
      planid: null,
      isCategoryFinished: false,
      fixedImageUrl: "",
      edgeNodes:['.b-GSD','.b-concur','.b-gov']
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'loading'
    ])
  },
  created() {
    const that = this
    // 1,通过url拿token(done) 2,将token加入 3,获取个人信息
    const token = getUrlParameter('dpjwt')
    // console.log('dpjwt', token)
    /** ****************/
    getUser().then(res => {
      if (res.code == "200") {
        if (res.SysUser) {

        } else {
          // location.href = "https://portal-uat.roche.com.cn/"
        }
      } else {
        // location.href = "https://portal-uat.roche.com.cn/"
      }
    })
    // 2.获取卡片动态数据，title,链接与链接名称
    getIteminfos().then(async res => {
      // console.log('1.json', res.data)
      that.originDataArray = res.data
      const obj = {}
      res.data.map((item, index) => {
        Object.keys(item).map((key, it) => {
          obj[key] = item[key]
        })
      })
      try {
        let cnRes = await getCn()
        let enRes = await getEn()
        console.log("mocktrainList", mocktrainList)
        let TrainList = await getMobileoute()
        // let TrainList = mocktrainList
        that.$i18n.setLocaleMessage('zh', {
          ...zhLocale,
          ...cnRes.data
        })
        that.$i18n.setLocaleMessage('en', {
          ...enLocale,
          ...enRes.data
        })
        getImageinfo().then(async res => {
          console.log("res", res)
          let fixedImageUrl = await that.chargeBase64(res.data?.levelkey)
          that.fixedImageUrl = fixedImageUrl.replace(/[\r\n]/g, "")
        })
        if (TrainList.code == '200') {
          that.progressTrainList = deepClone(TrainList.data)
          if (TrainList.data.length > 0) {
            that.orgintrainList = deepClone(TrainList.data)
            that.nextStep = that.getSteps(that.progressTrainList)
            let compSvg = that.progressTrainList[0].compSvg
            let imageUrl = await that.chargeBase64(compSvg.filekey)
            that.trainArray = that.getTrainArray(that.progressTrainList, that.currentIndex)
            // let imageUrl = await that.chargeBase64("2b958213-fbe8-48a7-9025-3320f2c8cdb6")
            that.imageUrl = imageUrl.replace(/[\r\n]/g, "")
            that.trainName = that.progressTrainList[this.currentIndex].planname
            that.planid = that.progressTrainList[this.currentIndex].id
            // console.log('TrainList', TrainList.data)
            // that.init()
          } else {
            that.trainArray = []
          }
        }
        that.allData = obj
        // console.log('allData', that.allData)
        that.showLoader = false;
      } catch (error) {
        that.showLoader = false;
      }
    })
  },
  mounted() {
    const that = this;// eslint-disable-line no-unused-vars
    // console.log("i18n",this.$i18n);
    // this.$i18n.locale = 'zh';
    (function ($) {
      function isIE() { // ie?
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          return true
        } else {
          return false
        }
      }
      // var initViewport = function(height){
      //     var metaEl = document.querySelector("#viewportMeta");
      //     var content = "width=device-width,initial-scale=1.0,user-scalable=no,maximum-scale=1.0,height=" + height;
      //     metaEl.setAttribute('name', 'viewport');
      //     metaEl.setAttribute('content', content);
      //   }

      //   initViewport(window.innerHeight);

      // var winHeight = $(window).height(); //获取当前页面高度  
      //     $(window).resize(function() {  
      //     initViewport(window.innerHeight);
      //     });  

      if (isIE()) {
        $('[data-static].b-photo').show()
        $('svg.b-photo').hide()
      } else {
        $('[data-static].b-photo').hide()

        $('svg.b-photo').show()
      }
      // 加载动画
      // if (isIE()) {
      //   $('.page-loader').hide()
      // } else {
      //   $(window).on('load', function() {
      //     if ($('div').is('.page-loader')) {
      //       $('.page-loader').delay(500).fadeOut('fast')
      //     }else{
      //       $('.page-loader').hide()
      //     }
      //   })
      // }
      var startX
      var clientHeight
      // 横竖屏判断
      var mql = window.matchMedia('(orientation: portrait)')
      function onMatchMeidaChange(mql) {
        if (mql.matches) {
          startX = 0
          // console.log('竖屏')
        } else {
          setTimeout(function () {
            $('[data-animate]').addClass('animated')
          }, 1000)

          var screenW = $(window).width()
          var mapScreenW = $('.map-con').width()
          startX = (mapScreenW - screenW) / 2
          clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
          // orientationScroll()
          // 横屏
          // console.log('横屏')
        }
      }
      onMatchMeidaChange(mql)
      mql.addListener(onMatchMeidaChange)

      $(window).on('resize', function () {
        if (!mql.matches) {
          var nowClientHeight = document.documentElement.clientHeight || document.body.clientHeight;

          if (clientHeight > nowClientHeight) {
            //键盘弹出的事件处理
            if (isAndroid()) {
              if (window.orientation == 90 || window.orientation == -90) {
                if ($(".search-input").is(":focus")) {
                  $("#topInput").show()
                }
                if ($("#feedbackCont").is(":focus")) {
                  $(".hello").hide()
                }
              }
            }
          }
          else {
            //键盘收起的事件处理
            if (isAndroid()) {
              if (window.orientation == 90 || window.orientation == -90) {
                $("#topInput").hide()
                $(".hello").show()
              }
            }
          }
        }
      });
      // 滚动实例

      that.mapScroll = new BScroll('.map-wrap', {
        scrollX: true,
        tap: true,
        startX: -startX,
        click: true,
        bounce: false,
        eventPassthrough: 'vertical' // 保留原生的纵向滚动
      })

      var flag = true

      function orientationScroll() { // eslint-disable-line no-unused-vars
        if (flag === true) {
          var screenW2 = $(window).width()
          var mapScreenW2 = $('.map-con').width()
          var startX2 = (mapScreenW2 - screenW2) / 2
          // console.log(screenW2,mapScreenW2,startX2)
          that.mapScroll.scrollTo(-startX2, 0, 500)
        }
        flag = false
      }
      // 救护车动画
      var tl = new TimelineMax()
      var car = $('.b-ambulance')
      tl.to(car, 4, { marginLeft: '20vh' })

      // 左右箭头点击事件
      $('#left_arrow').on('click', function (e) {
        var div = $("div [class='map-wrap-inner']")[0]
        var indexE = div.style.transform.indexOf('px')
        var indexS = 10
        var num = div.style.transform.substr(indexS, indexE - indexS)
        var newNum = parseFloat(num) + $('body')[0].offsetWidth
        if (newNum > 0) {
          newNum = 0
        }
        // div.style.transform = div.style.transform.replace(num, newNum)

        that.mapScroll.scrollTo(newNum, 0)
      })
      $('#right_arrow').on('click', function (e) {
        var div_out = $('#map_wrap')[0]
        var div = $("div [class='map-wrap-inner']")[0]
        var indexE = div.style.transform.indexOf('px')
        var indexS = 10
        var num = div.style.transform.substr(indexS, indexE - indexS)
        var newNum = parseFloat(num) - $('body')[0].offsetWidth
        if (newNum < 0 - div.offsetWidth + div_out.offsetWidth) {
          newNum = 0 - div.offsetWidth + div_out.offsetWidth
        }
        // div.style.transform = div.style.transform.replace(num, newNum)
        that.mapScroll.scrollTo(newNum, 0)
      })
      // $('#Catalog').on('click', function() {
      //   window.open('./目录.xlsx', '_self');
      // });
      // 发送邮件
      $('#sendmail').on('click', function () {
        if (!$('#feedbackCont').val()) {
          alert('发送内容不能为空')
          return false
        }
        $('#Opinion .icon-guanbi').trigger('click')
        $('#toast').show()
        const content = $('#feedbackCont').val()
        const createdtime = that.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        // const createdtime = new Date()
        sendFeedback({ content, createdtime }).then((res) => {
          if (res.code == "500") {
            that.content = ''
            $('#toast').hide()
            alert('提交失败')
          } else {
            $('#feedbackCont').val('')
            that.content = ''
            $('#toast').hide()
            alert('提交成功')
          }
        }).catch((err) => {
          // console.log(err) // 处理错误
          that.content = ''
          $('#toast').hide()
          alert('提交失败')
        })
      })
    })(jQuery)
    // console.log("test")
    // $.fancybox.open(
    //     $("#successCompleteDiag"), {
    //     modal: true
    //   })
    // if(isMobile()){
    //   $('[data-fancybox]').fancybox({
    //     touch:false,
    //     afterShow:function(instance,current){
    //       let currentName = $(current.src).attr("id")
    //       $('#'+currentName+" div").css('display','block')
    //     }
    //   })
    // }
  },
  methods: {
    openFancybox(event,src){
      event.preventDefault();
      event.stopPropagation();
      setTimeout(() => {
      //   $.fancybox.open({
      //   src,
      //   modal:false
      // })
      let that = this
      event.stopPropagation();
      $.fancybox.open($(src), {
        modal:false,
        'afterShow': function () {
          if (that.progressTrainList.length > 0) {
            that.triggerAfterShow()
          }

        },
        'afterClose': function (instance, current, e) {
          if (that.progressTrainList.length > 0) {
            that.triggerAfterClose(current)
          }
        }
      })
      $(src + " .fancybox-close-small").hide()
      }, 100);
    },
    triggerAfterShow() {
      let that = this;
      let id = $(".fancybox-content").attr("id")
      let step = that.getMappingName(that.nextStep[that.step])
      if (id === step) {
        that.cancelPicAnimation()
        let notFinished = that.checkCurrentTraingStatus(that.currentIndex, id)
        // console.log(id + "是否没完成" + notFinished)
        if (!notFinished) {
          // 已完成
          setTimeout(() => {
            that.setTitleToCompletedItems(that.currentIndex, id)
          }, 2000);
          setTimeout(() => {
            that.goToNext()
          }, 3000);

        }
      }
    },
    triggerAfterClose(current) {
      let that = this;
      let index = 0;
      let currentName = $(current.src).attr("id")
      let name = that.getMappingName(that.nextStep[that.step])
      // 如果该大项全部已读，标记笑脸
      // 如果该大项全部已读，则自动走向下一步
      let notFinished = that.checkCurrentTraingStatus(that.currentIndex, name)
      if (currentName == name) {
        if (!notFinished && !that.isCurrentTrainCompleted(index)) {
          setTimeout(() => {
            that.setTitleToCompletedItems(index, name)
          }, 500);
          setTimeout(() => {
            that.goToNext()
          }, 1000);
        } else if (!notFinished && that.isCurrentTrainCompleted(index)) {
          setTimeout(() => {
            that.setTitleToCompletedItems(index, name)
            // that.myCurrentNode = that.trainArray.length + 1
          }, 500);
          setTimeout(() => {
            that.cleanTitleToAllItems(this.currentIndex)
            that.gotoCompleteTraining()
          }, 1000);
        }
      }
    },
    // initTrainWelcome() {
    //   this.init()
    // },
    testDownloadBlob() {
      console.log("测试")
      downloadBlob().then(res => {
        console.log(res.data)
        const blob = new Blob([res.data])
        saveAs(blob, res.headers.name)
      })
    },
    opneDivingLicense() {
      this.showDivingLicense = true
    },
    openTrain() {
      let that = this;
      $.fancybox.open(
        $("#TrainDialog"), {
        modal: false,
        beforeClose: function (instance, current, e) {
          if (that.progressTrainList.length > 0) {
            that.init()
          }
        }
      })
      $("#TrainDialog .fancybox-close-small").hide()
    },
    getFinishedNum(index) {
      let count = 0;
      let foundFalse = false;
      this.progressTrainList[index].plandetails.some(item => {
        if (item.isfinish && !foundFalse) {
          count++;
        } else if (!item.isfinish) {
          foundFalse = true;
          return true; // 终止遍历  
        }
        return false; // 继续遍历  
      })
      return count
    },
    // 初始化角色，样式
    init() {
      let that = this;
      //既定路线
      that.curve = document.getElementById("curve")
      //实际画线
      // that.curve2 = document.getElementById("curve2")
      that.pathLength = curve.getTotalLength()
      that.human = document.getElementById("myTooltip")

      // 获取当前点的step
      that.step = that.getFinishedNum(0)
      //获取第一个点的位置，通过位置获取长度，然后通过长度获取坐标点
      this.originLen = that.getMappingLen(that.nextStep[that.step])
      const point = curve.getPointAtLength(this.originLen)

      //获取提示
      this.animationTips = this.getMappingName(this.nextStep[this.step])
      let rect = that.human.getBoundingClientRect();
      let humanHeight = rect.height;
      let humanWidth = rect.width;
      let windowHeight = window.innerHeight
      that.human.style.transform = `translate(${point.x - (humanWidth / (2 * (windowHeight / 585)))}px,${point.y - (2 * humanHeight / (3 * (windowHeight / 585)))}px)`

      that.displayPicAnimation()
      if (isMobile()) {
        let anchor = this.getMappingAnchor(this.nextStep[this.step])
        this.mapScroll.scrollToElement($(" " + anchor  + " .b-text")[0], 1000);
        if(!that.edgeNodes.includes(anchor)){
          that.mapScroll.scrollBy(humanWidth * 1.5 , 0, 1500)
        }
      } else {
        this.mapScroll.scrollToElement($(" " + this.getMappingPos(this.nextStep[this.step]) + " .b-text")[0], 1000);
      }
      // that.maxLen = that.getMaxLen()
      // that.drawGrayLine()
      that.setTitleToAllItems(that.currentIndex)
      // 标记已完成,笑脸
      that.setCompleteToItems(that.step)
      //监控fancybox显示的情况
      $(document).ready(function () {
        $("[data-fancybox]").fancybox({
          'afterShow': function () {
            if (that.progressTrainList.length > 0) {
              that.triggerAfterShow()
            }

          },
          'afterClose': function (instance, current, e) {
            if (that.progressTrainList.length > 0) {
              that.triggerAfterClose(current)
            }
          }
        })
      })

      this.autoGotoNext()
      setTimeout(() => {
        this.showTooltip = true
      }, 1000);
    },
    chargeBase64(key) {
      let data = ""
      return new Promise(resove => {
        getDownloadBase64(key).then(res => {
          if (res && res.name && res.name.includes(".")) {
            let h = res.name.substring(res.name.lastIndexOf('.') + 1)
            let prefix = getPreifx(h);
            data = prefix + res.base64;
            resove(data);
          } else {
            resove("")
          }

        })
      })
    },
    // 检查当前步数是否已完成，如果完成，则自动下一步
    autoGotoNext() {
      let that = this;
      if (that.checkCurrentStepTraining(that.currentIndex, that.step)) {
        // 置当前状态为完成
        let name = that.getMappingName(that.nextStep[that.step])
        setTimeout(() => {
          that.setTitleToCompletedItems(that.currentIndex, name)
        }, 2000);
        setTimeout(() => {
          that.goToNext()
        }, 3000);
      }
    },
    // 查看当前step培训是否已经完成
    checkCurrentStepTraining(index, step) {
      return this.progressTrainList[index].plandetails[step].isfinish
    },
    cleanAllTrainElements() {
      let that = this;
      that.myProgress = document.getElementById("myProgress")
      that.cleanTitleToAllItems(that.currentIndex)
      that.curve.style.display = 'none';
      // that.curve2.style.display = 'none';
      // that.curve2.removeAttribute('visibility');
      that.human.style.display = 'none';
      that.myProgress.style.display = 'none';
      that.orgintrainList = []
      that.progressTrainList = []
      that.trainArray = []
    },
    initTrain() {
      let that = this;
      that.step = 0
      that.nextStep = that.getSteps(that.progressTrainList)
      that.myCurrentNode = 1
      that.myPercent = 0
      that.progress = 0
      // 比较地图上需要培训的点
      //获取第一个点的位置，通过位置获取长度，然后通过长度获取坐标点
      this.originLen = that.getMappingLen(that.nextStep[that.step])
      const point = curve.getPointAtLength(this.originLen)
      const myTooltip = document.getElementById("myTooltip")
      //获取提示
      this.animationTips = this.getMappingName(this.nextStep[this.step])
      let rect = that.human.getBoundingClientRect();
      let humanHeight = rect.height;
      let humanWidth = rect.width;
      let windowHeight = window.innerHeight
      that.human.style.transform = `translate(${point.x - (humanWidth / (2 * (windowHeight / 585)))}px,${point.y - (2 * humanHeight / (3 * (windowHeight / 585)))}px)`

      if (isMobile()) {
        let anchor = this.getMappingAnchor(this.nextStep[this.step])
        this.mapScroll.scrollToElement($(" " + anchor  + " .b-text")[0], 1000);
          if(!that.edgeNodes.includes(anchor)){
            that.mapScroll.scrollBy(humanWidth * 1.5 , 0, 1500)
          }
      } else {
        this.mapScroll.scrollToElement($(" " + this.getMappingPos(this.nextStep[this.step]) + " .b-text")[0], 1000);
      }
      // that.maxLen = that.getMaxLen()
      // that.drawGrayLine()
      that.setTitleToAllItems(that.currentIndex)
      that.displayPicAnimation()
    },
    // 是否完成当前培训
    isCurrentTrainCompleted(index) {
      return this.progressTrainList[index].plandetails.every(item => item.isfinish)
    },
    getTrainArray(data, index) {
      let that = this
      let arr = []
      data[index].plandetails.map(item => {
        let pos = that.getMappingAnchor(item.id).substring(1).toLowerCase();;
        let nickName = that.getMappingNickName(item.id)
        let id = item.id
        arr.push({ pos, nickName, id })
      })
      // for(let i =0; i< 15; i++){
      //   arr.push({pos:'b-venue',nickName:'测试'})
      // }
      return arr
    },
    // 获取当前培训顺序
    getSteps(data) {
      let arr = []
      //获取第几条培训
      let index = this.currentIndex
      // 获取需要培训的地图上的各个点
      data[index].plandetails.map(item => {
        arr.push(item.id)
      })
      return arr
    },
    // 培训列表中的项目在地图中不存在
    deleteNotExistsTrainItems(itemid, name) {
      let index = this.currentIndex
      let that = this
      // 标记已读
      this.progressTrainList[index].plandetails = that.progressTrainList[index].plandetails.map(item => {
        if (item.id === name) {
          item.children = item.children.filter(it => it.id != itemid)
        }
        return item
      })
      // console.log("that.progressTrainList", that.progressTrainList)
    },
    // 子组件传递过来。1,点击标记已读 2,检查当前大项是否已经全部已读
    changeTrainList(itemid, name) {
      let index = this.currentIndex
      let that = this
      // 标记已读
      that.progressTrainList[index].plandetails.map(item => {
        if (item.id === name) {
          item.children.map(it => {
            if (it.id === itemid) {
              return { ...it, isfinish: true }
            }
          })
        }
      })
      // 将大项的isFinished更改
      that.progressTrainList[index].plandetails.map(item => {
        if (item.id === name) {
          let flag = item.children.every(it => it.isfinish === true)
          that.isCategoryFinished = flag
          return item.isfinish = flag
        }
      })
    },
    // 点击完成培训
    async completeTrain() {
      // 进入下一个培训
      let that = this;
      try {
        sendfinish(that.planid)
        let trainList = await getMobileoute()
        if (trainList.code == '200') {
          // 判断是否还有培训
          if (this.progressTrainList.length > 0) {
            that.orgintrainList = deepClone(trainList.data)
            that.progressTrainList = deepClone(trainList.data)
            that.trainArray = that.getTrainArray(that.progressTrainList, that.currentIndex)
            that.gotoNewTraining()
          } else {
            //结束
            that.cleanAllTrainElements()
          }
        } else {
          that.cleanAllTrainElements()
        }
      } catch (error) {
        that.cleanAllTrainElements()
      }

    },
    gotoCompleteTraining() {
      this.trainName = this.progressTrainList[this.currentIndex].planname
      // 完成当前全部培训
      $.fancybox.open(
        $("#successCompleteDiag"), {
        modal: true
      })
    },
    gotoNewTraining() {
      // 完成当前全部培训
      let that = this;
      this.trainName = this.progressTrainList[this.currentIndex].planname
      that.planid = this.progressTrainList[this.currentIndex].id
      $.fancybox.open(
        $("#TrainDialog"), {
        modal: false,
        beforeClose: function (instance, current, e) {
          that.initTrain()
        }
      })
      $("#TrainDialog .fancybox-close-small").hide()
    },
    getMyTrainList(name) {
      let index = this.currentIndex //第几个培训
      let that = this
      let arr = that.progressTrainList[index]?.plandetails.filter(item => item.id === name)
      return arr?arr:[]
    },
    // 更新进度
    updateProgress(index) {
      let that = this
      let total = that.progressTrainList[index].plandetails.length
      let progress = that.progressTrainList[index].plandetails.filter(item => item.isfinish).length
      return Math.round((progress / total) * 100)
    },
    // 将已完成的站点标记笑脸
    setTitleToCompletedItems(index, itemid) {
      let that = this
      let q = that.getMappingAnchor(itemid)
      // $(q + " >.b-photo").attr("content-before", "")
      let newContent = this.getWaitStudyCount(itemid)

      $(q + " >.b-photo").removeAttr("content-before")
      $(q + " >.b-photo").attr("dataCompleted-before", "")
      $(q + " >.b-photo[dataCompleted-before]").attr('data-attr', that.$i18n.tc("Sign.title1") + newContent + that.$i18n.tc("Sign.title2") + '😀')

      that.myPercent = that.updateProgress(index)
      if (that.myCurrentNode < that.trainArray.length && that.myCurrentNode < that.step + 2 || that.myCurrentNode == 1) {
        that.myCurrentNode = ++that.myCurrentNode
      }
    },
    // 标记step已完成站点
    setCompleteToItems(step) {
      let that = this;
      for (let i = 0; i < step; i++) {
        let itemid = that.nextStep[i]
        that.setTitleToCompletedItems(that.currentIndex, itemid)
      }
    },
    // 清空当前所有站点
    cleanTitleToAllItems(index) {
      let that = this
      that.progressTrainList[index]?.plandetails.map(item => {
        let q = that.getMappingAnchor(item.id)
        $(q + " >.b-photo").removeAttr("content-before")
        $(q + " >.b-photo").removeAttr("dataCompleted-before")
        $(q + " >.b-photo").css({
          'animation': 'none'
        })
        $(q + " >.b-text").css({
          'animation': 'none'
        })
        $(q + ' >[data-static].b-photo').hide()
        $(q + ' >svg.b-photo').show()
      })
    },
    // 标记第几站
    setTitleToAllItems(index) {
      let that = this
      that.progressTrainList[index]?.plandetails.map(item => {
        let q = that.getMappingAnchor(item.id)
        $(q + " >.b-photo").attr("content-before", "")
        let newContent = this.getWaitStudyCount(item.id)
        $(q + " >.b-photo[content-before]").attr('data-attr', that.$i18n.tc("Sign.title1") + newContent + that.$i18n.tc("Sign.title2"))
        $(q + ' >[data-static].b-photo').show()
        $(q + ' >svg.b-photo').hide()
      })
    },
    displayPicAnimation() {
      let that = this
      let q = that.getMappingAnchor(that.nextStep[that.step])
      let name = that.getMappingName(that.nextStep[that.step])
      $(q + " >.b-photo").css({
        'animation': 'myScale 2s infinite'
      })
      $(q + " >.b-text").css({
        'animation': 'myScale 2s infinite'
      })
      // $(q + " >.b-photo").attr("data-before", "")
      // 暂时不要待培训显示
      // $(q + " >.b-photo").removeAttr("content-before")
      // const newContent = this.getWaitStudyLen(name)
      // $(q + " >.b-photo[data-before]").attr('data-attr', newContent + '个项目待培训')

    },
    cancelPicAnimation() {
      let that = this
      let q = that.getMappingAnchor(that.nextStep[that.step])
      let name = that.getMappingName(that.nextStep[that.step])
      $(q + " >.b-photo").css({
        'animation': 'none'
      })
      $(q + " >.b-text").css({
        'animation': 'none'
      })
      //  $(q+" >.b-photo").removeAttr("data-before")
      // $(q + " >.b-photo").attr("content-before", "")
      // let newContent = this.getWaitStudyCount(name)
      // $(q + " >.b-photo[content-before]").attr('data-attr', '第' + newContent + '站')
    },
    onClick(item, key) {
      let that = this;
      if (key === "zh") {
        let copyUrl = item.cnsource ? item.cnsource : item.ensource
        let type = item.cntype ? item.cntype : item.entype
        if (copyUrl) {
          if (type == "3") {
            this.isVpnflag = true;
            this.$nextTick(() => {
              that.$confirm({
                title: that.$i18n.tc('LinksFor.title'),
                content: that.$i18n.tc('LinksFor.message'),
                okText: that.$i18n.tc('LinksFor.okText'),
                cancelText: that.$i18n.tc('LinksFor.cancelText'),
                onOk() {
                  window.open(copyUrl, '_blank');
                },
                onCancel() { },
                zIndex: 99999
              });
            })
          } else if (type == "2") {
            downloadFile(item.id)
          } else {
            window.open(copyUrl, '_blank');
          }
        } else {
          alert("中英文资源缺省，请上传对应资源")
        }
      } else if (key === "en") {
        let copyUrl = item.ensource ? item.ensource : item.cnsource
        let type = item.entype ? item.entype : item.cntype
        if (copyUrl) {
          if (type == "3") {
            this.isVpnflag = true;
            this.$nextTick(() => {
              that.$confirm({
                title: that.$i18n.tc('LinksFor.title'),
                content: that.$i18n.tc('LinksFor.message'),
                okText: that.$i18n.tc('LinksFor.okText'),
                cancelText: that.$i18n.tc('LinksFor.cancelText'),
                onOk() {
                  window.open(copyUrl, '_blank');
                },
                onCancel() { },
                zIndex: 99999
              });
            })
          } else if (type == "2") {
            downloadFile(item.id)
          } else {
            window.open(copyUrl, '_blank');
          }
        } else {
          alert("中英文资源缺省，请上传对应资源")
        }

      }
    },
    drawGrayLine() {
      let that = this
      //获取每一个节点，如果当前节点距离<前一个的距离，则使用前一个距离，否则保存前一个的距离，如果当前距离等于
      //最长，则len一直不变

      let now = 0;
      let preLen = 0
      //如果step等于0
      if (this.step > 0) {
        preLen = that.getMappingLen(this.nextStep[this.step])
      }
      let nexLen = that.getMappingLen(this.nextStep[this.nextStep.length - 1])
      if (this.step < this.nextStep.length - 1) {
        nexLen = that.getMappingLen(this.nextStep[this.step + 1]) //下一个节点
      }
      if (nexLen < preLen) {
        now = preLen
      } else {
        now = nexLen
      }

      // that.curve2.style.strokeDasharray = now+" "+that.pathLength
      // that.curve2.style.display = 'block'
      // that.curve2.setAttribute('visibility', 'visible');
    },
    animate() {
      //获取初始位置
      let that = this
      const point = that.curve.getPointAtLength(that.originLen + that.progress);
      let rect = that.human.getBoundingClientRect();
      let humanHeight = rect.height;
      let humanWidth = rect.width;
      let windowHeight = window.innerHeight
      if (that.direction > 0) {
        that.human.style.transform = `translate(${point.x - (humanWidth / (2 * (windowHeight / 585)))}px,${point.y - (2 * humanHeight / (3 * (windowHeight / 585)))}px)`
      } else {
        that.human.style.transform = `translate(${point.x + (humanWidth / (2 * (windowHeight / 585)))}px,${point.y - (2 * humanHeight / (3 * (windowHeight / 585)))}px)`
      }
      if (!that.lastNumberSign) {
        that.human.style.transform += ' scaleX(-1)'
      } else {
        // 如果有组合的情况，需要更复杂的处理  
        var newTransformValue = that.human.style.transform.replace(/scaleX\(-1\)/g, '');

        // 去除可能的空格和多余的 ','  
        newTransformValue = newTransformValue.replace(/\s+/g, ' ').trim().replace(/,\s*/g, ',').replace(/,$/, '');

        // 如果 newTransformValue 为空字符串，则可能需要设置为 'none' 或保持原样  
        if (newTransformValue === '') {
          newTransformValue = 'none'; // 或者你可以选择保持原样，即不设置 transform  
        }

        // 应用新的 transform 值到元素上  
        that.human.style.transform = newTransformValue;
      }

      that.progress += that.direction;


      // that.curve.style.strokeDasharray = that.progress+" "+that.pathLength

      /********实际轨迹填充***********/
      // that.curve.style.strokeDasharray = (that.originLen + that.progress)+" "+that.pathLength
      // that.curve.style.display = 'block';
      /*******************/

      if ((that.originLen + that.progress) === that.getMappingLen(this.nextStep[this.step])) {
        // console.log("到达" + that.progress)
        that.isMoving = false;
        cancelAnimationFrame(that.requestId)
      }

      if ((that.originLen + that.progress) >= that.pathLength || (that.originLen + that.progress) <= 0) {
        // console.log(that.progress, "超过总长或小于0")
        cancelAnimationFrame(that.requestId);
        that.progress = 0;
        return
      }

      if (that.isMoving) {
        that.requestId = requestAnimationFrame(that.animate)
      }
    },
    openCategory(event, category) {
      let that = this
      event.stopPropagation();
      let r = "#" + category
      $.fancybox.open($(r), {
        'afterShow': function () {
          if (that.progressTrainList.length > 0) {
            that.triggerAfterShow()
          }

        },
        'afterClose': function (instance, current, e) {
          if (that.progressTrainList.length > 0) {
            that.triggerAfterClose(current)
          }
        }
      })
      $(r + " .fancybox-close-small").hide()
    },
    getMappingCount(name) {
      // 测试数据
      let index = this.currentIndex //第几条培训计划，默认第一个.后面动态加减
      let arr = this.progressTrainList[index].plandetails.filter(item => item.id === name)
      return arr.length > 0 ? arr[0].count : ""
    },
    getStudyName(index) {
      return this.progressTrainList[index]?.planname
    },
    getWaitStudyLen(name) {
      // 测试数据
      let index = this.currentIndex //第几条培训计划，默认第一个.后面动态加减
      let arr = this.progressTrainList[index].plandetails.filter(item => item.id === name)[0]?.children?.filter(it => it.isfinish === false)
      return arr?.length
    },
    getWaitStudyCount(name) {
      // 测试数据
      let index = this.currentIndex //第几条培训计划，默认第一个.后面动态加减
      let arr = this.progressTrainList[index].plandetails.findIndex(item => item.id === name)
      return arr + 1
    },
    getMappingPath(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].path : ""
    },
    getMappingTips(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].tips : ""
    },
    getMappingAnchor(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].anchor : ""
    },
    getMappingName(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].name : ""
    },
    getMappingPos(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].pos : ""
    },
    getMappingNickName(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].nickName : ""
    },
    getMappingLen(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].len : 0
    },
    getMaxLen() {
      let that = this
      let arr = []
      that.nextStep.map(item => {
        arr.push(that.getMappingLen(item))
      })
      let max = Math.max(...arr)
      return max
    },
    getAnimationDur(index) {
      return this.nextS[index]
    },
    getMappingDur(name) {
      let arr = this.animationMapping.filter(item => item.name === name)
      return arr.length > 0 ? arr[0].dur : 0
    },
    getNumber() {
      this.reciprocal = this.content.length;
    },
    clickHuman() {
      this.showTooltip = !this.showTooltip
    },
    changeActive($event) {
      $event.currentTarget.className = 'b-text feedbackbtns-active feedbackbtns-myhg';
    },
    removeActive($event) {
      $event.currentTarget.className = 'b-text feedbackbtns feedbackbtns-myhg';
    },
    changeActive2($event) {
      $("#dropdownButton").attr("class", "b-text feedbackbtns-active feedbackbtns-myhg")
    },
    removeActive2($event) {
      $("#dropdownButton").attr("class", "b-text feedbackbtns feedbackbtns-myhg")
    },
    onSearchFocus(evt) {
      if (isAndroid()) {
        if (window.orientation == 90 || window.orientation == -90) {
          let height = document.documentElement.clientHeight;
          // $(".input-holder").css("height",height)
          $("#topInput").css("height", height)
          $("#topInput").show()
        }
      }
    },
    onBlur() {
      if (isAndroid()) {
        if (window.orientation == 90 || window.orientation == -90) {
          $("#topInput").hide()
          // $(".input-holder").css("height",'6.5515625vh')
        }
      }
    },
    onTextareaFocus() {
      if (isAndroid()) {
        if (window.orientation == 90 || window.orientation == -90) {
          $(".hello").hide()
        }
      }
    },
    onTextareaBlur() {
      if (isAndroid()) {
        if (window.orientation == 90 || window.orientation == -90) {
          $(".hello").show()
        }
      }
    },
    //检查当前类别的培训是否全部完成
    checkCurrentTraingStatus(index, category) {
      // index表示当前第几个培训， category表示类别
      let arr = this.progressTrainList[index].plandetails.filter(item => item.id === category)
      if (arr.length > 0) {
        if (arr[0].children.length === 0) {
          return false
        }
        return !arr[0].isfinish
      }
      return false
    },
    //前往下一站
    goToNext() {
      let that = this
      if (that.checkCurrentTraingStatus(this.currentIndex, that.getMappingName(that.nextStep[that.step]))) {
        this.$message.info(that.$i18n.tc("Sign.tips"));
        return
      }

      that.cancelPicAnimation()
      let oldStep = this.step
      if (this.step > this.nextStep.length - 1 || this.step === this.nextStep.length - 1) {
        this.step = 0
      } else {
        ++this.step
      }
      that.autoGotoNext()
      if (this.getMappingLen(this.nextStep[this.step]) > this.getMappingLen(this.nextStep[oldStep])) {
        that.direction = Math.abs(that.direction)
        that.lastNumberSign = true
      } else {
        that.direction = -Math.abs(that.direction)
        that.lastNumberSign = false
      }
      that.isMoving = true
      that.animate()
      // const myAnimation = document.getElementById("myAnimation")
      // this.animationStart = 'indefinite'
      // this.animationPath = this.getMappingPath(this.nextStep[this.step])
      this.animationTips = this.getMappingName(this.nextStep[this.step])
      // this.animationDur = this.getAnimationDur(this.step)
      // this.animationStart = '0s'
      // myAnimation.currentTime = 0
      // myAnimation.beginElement()
      let rect = that.human.getBoundingClientRect();
      let humanWidth = rect.width;
      let time = Math.abs(this.getMappingDur(this.nextStep[this.step]) - this.getMappingDur(this.nextStep[oldStep]))
      // console.log("time", time)
      if (isMobile()) {
        let anchor = this.getMappingAnchor(this.nextStep[this.step])
        this.mapScroll.scrollToElement($(" " + anchor + " .b-text")[0], 2*time);
        if(!that.edgeNodes.includes(anchor)){
          that.mapScroll.scrollBy((that.lastNumberSign?1:0.5)*humanWidth * 1.5, 0, 2*time + 1500)
        }
      } else {
        this.mapScroll.scrollToElement($(" " + this.getMappingPos(this.nextStep[this.step]) + " .b-text")[0], 2*time);
      }
      // this.mouseLeaveDelay = 0
      this.showTooltip = false
      that.displayPicAnimation()
      that.drawGrayLine()

      setTimeout(() => {
        this.showTooltip = true
      },  2*time + 1500);

      // 检查当前节点是否已经全部完成
      if (that.checkCurrentTraingStatus(this.currentIndex, that.getMappingName(that.nextStep[that.step]))) {
        let name = that.getMappingName(that.nextStep[that.step])
        // let arr = this.progressTrainList[this.currentIndex].plandetails.filter(item => item.id === name)
        // if (arr.length > 0 && arr[0].children.length === 0) {
        //   ++this.step
        // }
        if (this.progressTrainList[this.currentIndex].plandetails.length === (this.step)) {
          // 如果当前是最后一个培训,弹出培训完成
          //关闭当前培训框
          $.fancybox.close(
            $(`#${name}`), {
            modal: false
          })
          setTimeout(() => {
            that.setTitleToCompletedItems(index, name)
            // that.myCurrentNode = that.trainArray.length + 1
          }, 2000);
          setTimeout(() => {
            that.cleanTitleToAllItems(this.currentIndex)
            that.gotoCompleteTraining()
          }, 3000);

        } else {
          this.goToNext()
        }
      }

    },
    backToRobot() {
      let that = this;
      let rect = that.human.getBoundingClientRect();
      let humanWidth = rect.width;
      if (isMobile()) {
        this.mapScroll.scrollToElement($(" " + this.getMappingAnchor(this.nextStep[this.step]) + " .b-text")[0], 1000);
        that.mapScroll.scrollBy(humanWidth * 1.5, 0, 1500)
      } else {
        this.mapScroll.scrollToElement($(" " + this.getMappingPos(this.nextStep[this.step]) + " .b-text")[0], 1000);
      }
      setTimeout(() => {
        this.showTooltip = true
      }, 1200);
      // console.log("test")
    },
    searchToggle(evt) {
      // console.log('evt', evt)
      const obj = evt.currentTarget
      var container = $(obj).closest('.search-wrapper')
      if (!container.hasClass('active')) {
        container.addClass('active')
        evt.preventDefault()
      } else if (container.hasClass('active') && $(obj).closest('.input-holder').length === 0) {
        container.removeClass('active')
        // clear input
        container.find('.search-input').val('')
        // clear and hide result container when we press close
        container.find('.result-container').fadeOut(100, function () { $(this).empty() })
      }
    },
    submitFn(evt) {
      const that = this
      const obj = evt.currentTarget
      const value = $(obj).find('.search-input').val().trim()
      $.fancybox.open($('#SearchBody'))
      $('#SearchBody .fancybox-close-small').hide()
      // let _html = "您搜索的关键词： ";
      // if(!value.length){
      //   _html = "关键词不能为空。";
      // }
      // else{
      //   _html += "<b>" + value + "</b>";
      // }

      // $(obj).find('.result-container').html('<span>' + _html + '</span>');
      // $(obj).find('.result-container').fadeIn(100);
      this.queriesValue = value
      store.dispatch("setLoading", true)
      searchItems(value).then(res => {
        // console.log(res)
        store.dispatch("setLoading", false)
        if (res.rows.length > 0) {
          that.processedData = this.conversionData(res.rows)
          that.activeKey = []
          for (let i = 0; i < that.processedData.length; i++) {
            this.activeKey.push(String(that.processedData[i][0]))
          }
          that.activeKey = this.activeKey
          this.isShowSearch = true
        } else {
          that.processedData = []
          this.isShowSearch = false
        }
      }).catch(error => {
        store.dispatch("setLoading", false)
      })
    },

    // 转换courseData的数据结构的方法
    conversionData(courseData) {
      var list = courseData
      var flag = 0
      var processedData = []
      for (var i = 0; i < list.length; i++) {
        var az = ''
        for (var j = 0; j < processedData.length; j++) {
          if (processedData[j][1].category === list[i].category) {
            flag = 1; az = j; break
          }
        }
        if (flag === 1) {
          processedData[az].push(list[i])
          flag = 0
        } else if (flag === 0) {
          const wdy = []
          wdy.push(list[i].category, list[i])
          processedData.push(wdy)
        }
      }
      // console.log('processedData', processedData)
      return processedData
    }
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      // 当语言变化时，更新样式或DOM  
      // ...  
      this.setTitleToAllItems(this.currentIndex)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.toast {
  position: fixed;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  color: #fff;
  display: none;
}

.displayNone {
  display: none;
}

.toast .double-bounce1,
.toast .double-bounce2 {
  width: 40px;
  height: 40px;
  margin-top: 18px;
  margin-left: 20px;
}

.toast p {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
}

.feedbackbtn {
  width: 16.779296875vh;
  height: 6.5515625vh;
  line-height: 8.3515625vh;
  border-radius: 0 0 3vh 3vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
}

.feedbtn {
  display: inline-block;
  width: 100%;
  height: 8.3515625vh;
  line-height: 8.3515625vh;
  /* border-radius:0 0 16px 16px; */
}

.fabtn {
  position: relative;
  top: 8vh;
  left: -23.779296875vh;
  min-width: 147px;
}

.febtn {
  position: absolute;
  left: 23.779296875vh;
  width: 28.779296875vh;
  top: -10vh;
  border-radius: 0 0 3vh 3vh;
}

.febtn2 {
  margin-top: -2px;
}

.feedbackbtns {
  height: 100%;
  border-radius: 0 0 3vh 3vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
}

.i-aicon {
  position: absolute;
  right: 0.2rem;
}

.feedbackbtns-myhg {
  min-width: 23.779296875vh;
  justify-content: center;
}

.minfeedbackbtns {
  height: 6.5515625vh;
  line-height: 6.5515625vh;
  width: 28.779296875vh;
}

#app .b-feedback .feedbackbtns {
  border-top-width: 0;
}

.building {
  height: 100%;

}

.belowbtn {
  width: 12.779296875vh;
  height: 6.5515625vh;
  line-height: 8.3515625vh;
  border-radius: 16px 16px 0 0;
  position: fixed;
  bottom: 0;
  z-index: 101;
}

.hello .belowbtn .belowbtns {
  width: 20.779296875vh;
  height: 6.5515625vh;
  line-height: 8.3515625vh;
  border-radius: 3vh 3vh 0 0;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 101;
}

#app .hello .belowbtn .belowbtns2 {
  width: 24.779296875vh;
}

.b-feedback .belowbtns {
  border-bottom-width: 0 !important;
}

.ant-dropdown-menu-item-active {
  background-color: transparent;
}

.ant-click-animating-without-extra-node {}

.meunbtn {
  width: 18.779296875vh;
  height: 6.5515625vh;
  font-size: 2.5vh;
  line-height: 6.5515625vh;
  /*padding:0 10px;*/
  background: rgb(250, 230, 193);
  border: 0.6vh solid rgb(217, 150, 67);
  border-radius: 0 0 3vh 3vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  border-top-width: 0;
  color: rgb(113, 89, 53);
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 5px rgb(217, 150, 67);
}

.myTrainList {
  width: 18.779296875vh;
  height: 6.5515625vh;
  font-size: 2.5vh;
  line-height: 6.5515625vh;
  /*padding:0 10px;*/
  background: rgb(250, 230, 193);
  border: 0.6vh solid rgb(217, 150, 67);
  border-radius: 0 0 3vh 3vh;
  position: fixed;
  top: 0;
  left: 46vh;
  z-index: 100;
  border-top-width: 0;
  color: rgb(113, 89, 53);
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 5px rgb(217, 150, 67);
}

.b-feedback .b-text {
  position: absolute !important;
  display: inline-flex;
  width: 28.779296875vh;
  justify-content: center;
  align-items: center;
  background: rgb(250, 230, 193);
  border: 0.6vh solid rgb(217, 150, 67);
  padding: 0 10px;
  z-index: 101;
  color: rgb(113, 89, 53);
  font-weight: bold;
  box-shadow: 0 0 5px rgb(217 150 67);
  white-space: nowrap;
  font-size: 2.5vh;
}

/*********search**********/

.fancybox-stage .searchInps {
  z-index: 5;
}

.searchInput {
  width: 23.779296875vh;
  height: 6.5515625vh;
  position: fixed;
  /* right: 40%; */
  /* left: 251px; */
  left: 44vh;
  z-index: 10;
}

.search-wrapper {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.searchBtnP {
  display: inline;
  padding-left: 1.4vh;
  font-weight: bold;
}

.active .input-holder .searchBtnP {
  display: none;
}

.search-wrapper.active {}

.search-wrapper .input-holder {
  overflow: hidden;
  height: 6.551562vh;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  position: relative;
  width: 20.551562vh;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-wrapper.active .input-holder {
  border-radius: 50px;
  width: 40vw;
  height: 6.5515625vh;
  background: rgb(250, 230, 193);
  -webkit-transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  -moz-transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  /* right: 50%; */
  left: 50%;
}

.search-wrapper .input-holder .search-input {
  width: 100%;
  height: 6.5515625vh;
  padding: 0px 70px 0 20px;
  opacity: 0;
  position: absolute;
  top: -10px;
  left: 0px;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 2.5vh;
  font-weight: 400;
  line-height: 8.4vh;
  color: lightdark;
  -webkit-transform: translate(0, 60px);
  -moz-transform: translate(0, 60px);
  transform: translate(0, 60px);
  -webkit-transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  -moz-transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);

  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.search-wrapper.active .input-holder .search-input {
  opacity: 1;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  transform: translate(0, 10px);
}

.search-wrapper .input-holder .search-icon {
  width: 20.5515625vh;
  height: 6.5515625vh;
  /* line-height: 7.5515625vh; */
  /* border:none; */
  /* border-radius:6px; */
  font-size: 2.5vh;
  background: rgb(250, 230, 193);
  padding: 0px;
  outline: none;
  position: relative;
  z-index: 2;
  float: right;
  border: 0.6vh solid rgb(217, 150, 67);
  border-radius: 0 0 3vh 3vh;
  border-top-width: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-wrapper.active .input-holder .search-icon {
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  width: 5.5515625vh;
  height: 5.5515625vh;
  /* margin: 5px 8px 5px 5px; */
  border-radius: 50%;
  border-top-width: 0.6vh;
}

.search-wrapper .input-holder .search-icon span {
  width: 2.5vh;
  height: 2.5vh;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
  -moz-transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
  transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);

}

.search-wrapper.active .input-holder .search-icon span {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.search-wrapper .input-holder .search-icon span::before,
.search-wrapper .input-holder .search-icon span::after {
  position: absolute;
  content: '';
}

.search-wrapper .input-holder .search-icon span::before {
  width: 0.5vh;
  height: 1.6vh;
  left: 0.9vh;
  top: 1.8vh;
  border-radius: 2px;
  background: rgb(113, 89, 53);
}

.search-wrapper .input-holder .search-icon span::after {
  width: 2.5vh;
  height: 2.5vh;
  left: 0vh;
  top: -0.5vh;
  border-radius: 50%;
  border: 2px solid rgb(113, 89, 53);
}

.search-wrapper .close {
  position: absolute;
  z-index: 1;
  /* top: 1.7vh; */
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1.3vh;
  right: 1vw;
  width: 2.6vh;
  height: 2.6vh;
  cursor: pointer;
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
  -moz-transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
  transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.search-wrapper.active .close {
  /* right:55%; */
  left: 43%;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  -moz-transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.search-wrapper .close::before,
.search-wrapper .close::after {
  position: absolute;
  content: '';
  background: rgb(250, 230, 193);
  border-radius: 2px;
}

.search-wrapper .close::before {
  width: 0.6vh;
  height: 2.6vh;
  left: 1vh;
  top: 0px;
}

.search-wrapper .close::after {
  width: 2.6vh;
  height: 0.6vh;
  left: 0px;
  top: 1vh;
}

.search-wrapper .result-container {
  width: 100%;
  position: absolute;
  top: 80px;
  left: 0px;
  text-align: center;
  font-family: "Open Sans", Arial, Verdana;
  font-size: 14px;
  display: none;
  color: #B7B7B7;
}

#components-dropdown-demo-placement {
  z-index: 101;
  position: fixed;
  /* left: 8.5%; */
  /* left: 100px; */
  left: 18vh;
}

body .ant-btn {
  background-color: transparent;
  width: 18.779296875vh;
  height: 6.5515625vh;
  border: 0;

}

.ant-btn::after {
  width: 10vh;
}

.ant-dropdown-menu {
  background: transparent;
  box-shadow: none;
  top: -10px;
}

.feedbackbtns-active {
  height: 100%;
  border-radius: 0;
  border-top-width: 0 !important;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  transition: all .3s;
}

.ant-dropdown-menu-item {
  padding: 0;
  width: 0;
}

.ant-dropdown-menu-item:hover {
  background-color: transparent;
}

.ant-result {
  height: 100%;
  padding: 0;
  transform: scale(0.8);
}

.b-text-width {
  width: 100% !important;
}

.textareabox {
  position: relative;
}

.textareaSpan {
  position: absolute;
  bottom: 5%;
  right: 2%;
}

.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  padding: 30px;
}

@media screen and (max-width: 560px) {
  .search-wrapper.active .input-holder {
    width: 20rem;
  }

  .myTrainList {
    left: 33vh;
  }

  .hello .timeline {
    left: 3vh;
  }

  .hello .timelineImage {
    left: 3vh;
  }
}

.topInput {
  position: fixed;
  width: 100%;
  /*height: 20%;*/
  /*min-height: 150px;*/
  bottom: 0;
  z-index: 99999;
  padding: 1rem;
  font-size: 12px;
  vertical-align: bottom;
  line-height: 12px;
  padding-top: 20PX;
}

.openImage {
  width: 1rem;
  height: 1rem
}

.map {
  position: absolute;
  top: 1px;
  left: 0px;
  z-index: 0;
}

.myPosition {
  z-index: 1;
  position: absolute;
}

.mySection {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(./../assets/img/roche-map-bg.jpg)
}

.animationTips {
  color: #0000FF;
  text-decoration: none;
  /* animation: shine 2.4s infinite; */
  display: flex;
  align-items: center;
  /*  padding-right: 3vh;*/
}

.nextStage {
  transform: translate(-30%, -50%);
  background-color: transparent;
  color: white;
  width: 100%;
  text-shadow: 0px 0px 15px green, 0px 0px 15px green, 0px 0px 15px green;
}

.nextStage:hover {
  animation: bounce 2s infinite;
  /*设置动画*/
  display: inline-block;
}

@keyframes shine {

  0%,
  100% {
    color: #fff;
    text-shadow: 0 0 10px #0000FF, 0 0 10px #0000FF;
  }

  50% {
    text-shadow: 0 0 10px #0000FF, 0 0 40px #0000FF;
  }
}

@keyframes bounce {

  /*创建动画*/
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(30px);
  }

  60% {
    -webkit-transform: translateX(15px);
  }
}

[data-before]::before {
  content: attr(data-attr);
  font-size: 3vh;
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: 700;
  transform: translate(-30%, -50%);
  background-color: transparent;
  color: white;
  width: 100%;
  text-shadow: 0px 0px 15px red, 0px 0px 15px red, 0px 0px 15px red;
  z-index: 1;
}

[content-before]::before {
  content: attr(data-attr);
  font-size: 3vh;
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: 700;
  transform: translate(-30%, -50%);
  background-color: transparent;
  color: white;
  width: 100%;
  text-shadow: 0px 0px 15px rgb(72, 0, 255), 0px 0px 15px rgb(0, 55, 255), 0px 0px 15px rgb(76, 0, 255);
  z-index: 999;
}

[dataCompleted-before]::before {
  content: attr(data-attr);
  font-size: 3vh;
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: 700;
  transform: translate(-30%, -50%);
  background-color: transparent;
  color: white;
  width: 100%;
  text-shadow: 0px 0px 15px green, 0px 0px 15px green, 0px 0px 15px green;
  z-index: 1;
}


.timeline {
  position: fixed;
  left: 7vh;
  bottom: 1vh;
  width: 40vh;
  height: 10vh;
  z-index: 99;
}

.timelineImage {
  position: fixed;
  left: 7vh;
  bottom: 5vh;
  width: 15vh;
  height: 15vh;
  z-index: 99;
}

.timelineImage img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}


.studyName {
  text-align: center;
  font-weight: bold;
  position: relative;
  /* 设置立体
  text-transform: uppercase;
  text-shadow:
    0px 1px 0px #c0c0c0,
    0px 2px 0px #b0b0b0,
    0px 3px 0px #a0a0a0,
    0px 4px 0px #909090,
    0px 5px 10px rgba(0, 0, 0, 0.6);
  */
}

.b-modal {
  width: 100vw;
}
</style>
<style>
.myTooltip {
  opacity: 0.8;
}
</style>
